import React from "react"

import Container from "../components/container"
import Header from "../components/header";
import Layout from "../components/layout";
import Pdf from "../components/Pdf";


export default () => (
  <Layout>
    <Container>
      <h1>Past Edition</h1>
    </Container>
    <Pdf/>
  </Layout>
)